/* eslint-disable no-unused-vars */
export default {
  methods: {
    dialog(state) {
      return this.$store.getters["getDialogState"](state);
    },
    open(state, modulePath, id) {
      this.$store.dispatch(
        "updateDialog",
        { idx: state, status: true },
        { root: true }
      );
      if (modulePath) {
        this.$store.dispatch(modulePath, { id });
      }
    },
    close(state) {
      this.$store.dispatch(
        "updateDialog",
        { idx: state, status: false },
        { root: true }
      );
    }
  }
};
