<template>
  <v-container>
    <div
      v-if="pendingOrders.length > 0"
      class="ml-2 d-flex flex-row flex-grow-1"
    >
      <PageTitle
        class=" font font-weight-bold"
        text="List Of Pending Lube Requests"
      />
      <v-btn
        icon
        fab
        @click="getPendingRequests"
        small
        color="primary"
        class="mt-2 mr-4"
      >
        <i class="material-icons-outlined">refresh</i>
      </v-btn>
    </div>
    <v-row v-if="isPageLoading">
      <v-col cols="12" sm="12" align="center">
        <v-progress-circular
          indeterminate
          size="30"
          width="4"
          color="primary"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-if="pendingOrders.length > 0 && !isPageLoading"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="pendingOrders"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="isPageLoading"
              class=" font font-weight-medium elevation-1"
            >
              <template #item.avatar="{item}">
                <v-avatar color="blue" class="ma-1" size="40">
                  <span v-if="item.attendantId" class="white--text">{{
                    item.attendantId.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>
              <template #item.lubesOrdersLineItem="{item}">
                <v-chip small label class="font-weight-bold">{{
                  item.lubesOrdersLineItem.length
                }}</v-chip>
              </template>
              <template #item.isOrderReviewed="{item}">
                <v-chip
                  v-if="item.isOrderReviewed"
                  label
                  :color="item.isOrderReviewed ? '#9efb9e66' : '#f98c6b'"
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderReviewed
                        ? 'rgba(3, 91, 3, 0.83)'
                        : '#862c00'
                    }"
                    class="font-weight-bold"
                    >Reviewed</span
                  >
                </v-chip>
                <v-chip
                  v-else
                  label
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span class="font-weight-bold">{{ "Not Reviewed " }}</span>
                </v-chip>
              </template>
              <template #item.isOrderApproved="{item}">
                <v-chip
                  label
                  :color="
                    item.isOrderApproved ? '#9efb9e66' : 'rgb(247, 189, 172)'
                  "
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderApproved
                        ? 'rgba(3, 91, 3, 0.83)'
                        : 'rgb(238, 78, 0)'
                    }"
                    class="font-weight-bold"
                    >Pending</span
                  >
                </v-chip>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      dark
                      v-on="on"
                      small
                      :to="{
                        name: 'master.lubes.pending.requests.edit',
                        params: { id: item.id }
                      }"
                    >
                      <v-icon small color="black">edit</v-icon>
                    </v-btn>
                  </template>
                  <span class=" font font-weight-medium font-size-sm"
                    >Edit Lube Orders</span
                  >
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="open('alert'), (lubeRequestId = item.id)"
                      icon
                      dark
                      v-on="on"
                      small
                    >
                      <v-icon small color="black">delete</v-icon>
                    </v-btn>
                  </template>
                  <span class=" font font-weight-medium font-size-sm"
                    >Delete Lube Orders</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center" style="min-height: 500px;">
      <div class="d-flex flex-column flex-grow-1 " style="align-items:center;">
        <img src="@/assets/pallets.svg" style="width: 180px;" class="mb-3" />
        <span class="font font-weight-medium font-size-md pt-3"
          >No pending <kbd class="ml-1">lube order requests</kbd> available
          right now.</span
        >
        <span class="font font-weight-medium font-size-sm pt-2"
          >All pending requests will show up here !!!</span
        >
      </div>
    </v-row>
    <ConfirmationDialog
      :state="dialog('alert')"
      title="Delete Lube Request"
      :loading="isLoading"
      @save="deletePendingLube"
      @close="close"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { Api } from "../../api/Api";
import PageTitle from "../../components/PageTitle";
import DialogMixins from "../../mixins/DialogMixins";
export default {
  name: "PendingLubeRequests",
  components: {
    PageTitle,
    SnackBar: () => import(`../../components/SnackBar`),
    ConfirmationDialog: () => import(`../sales/components/ConfirmationDialog`)
  },
  data: () => ({
    pendingOrders: [],
    message: "",
    showSnackBar: false,
    status: "",
    isLoading: false,
    isPageLoading: false,
    lubeRequestId: null,
    headers: [
      {
        text: "Date",
        value: "date"
      },
      {
        text: "Order Reference",
        value: "orderReference"
      },
      {
        text: "Order Line Items",
        value: "lubesOrdersLineItem"
      },
      {
        text: "Reviewed Status",
        value: "isOrderReviewed"
      },
      {
        text: "Approval Status",
        value: "isOrderApproved"
      },
      {
        text: "Actions",
        value: "actions"
      }
    ],
    paginate: {
      page: 1,
      limit: 20,
      total: 0
    }
  }),
  mixins: [DialogMixins],
  methods: {
    async deletePendingLube() {
      try {
        this.isLoading = true;
        const response = await Api().delete(
          `/orders/lubes/${this.lubeRequestId}/pending/requests`
        );
        if (response) {
          this.showSnackBar = true;
          this.message = "Lube request successfully deleted";
          this.status = "success";
          setTimeout(() => {
            this.isLoading = false;
            this.close("alert");
            this.showSnackBar = false;
            this.getPendingRequests();
          }, 2000);
        }
      } catch (e) {
        this.close("alert");
        this.showSnackBar = true;
        this.message = e?.message ?? `Unable to delete pending lube request`;
        this.status = "red";
        setTimeout(() => {
          this.isLoading = false;
          this.showSnackBar = false;
        }, 2000);
      }
    },
    async getPendingRequests() {
      try {
        this.isPageLoading = true;
        const response = await Api().get(
          `/orders/lubes/pending/requests?isPaginated=true&page=${this.paginate.page}&limit=${this.paginate.limit}`
        );
        if (response) {
          this.isPageLoading = false;
          this.pendingOrders = response.data.paginateObj.docs;
          const { limit, page, pages } = response.data.paginateObj;
          this.paginate = { limit, page, total: pages };
        }
      } catch (e) {
        this.isPageLoading = false;
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get pending request list";
        this.status = "red";
      }
    }
  },
  created() {
    this.getPendingRequests();
  }
};
</script>
